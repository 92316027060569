import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "domovea Smarthome",
  "path": "/Software/Other_Platforms/Hager_Domovea/",
  "dateChanged": "2019-04-25",
  "author": "Mike Polinowski",
  "excerpt": "The intelligent building technology is developing rapidly. This means bringing more complex technology and different systems under one roof. With domovea by Hager, this is very easy and only with a single component! domovea is the simple, versatile and efficient smart home solution for residential and commercial buildings.",
  "social": "/images/Search/P_SearchThumb_Hager.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_Domovea_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Domovea"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='domovea Smarthome' dateChanged='2019-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The intelligent building technology is developing rapidly. This means bringing more complex technology and different systems under one roof. With domovea by Hager, this is very easy and only with a single component! domovea is the simple, versatile and efficient smart home solution for residential and commercial buildings.' image='/images/Search/P_SearchThumb_Hager.png' twitter='/images/Search/P_SearchThumb_Hager.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Hager_Domovea/' locationFR='/fr/Software/Other_Platforms/Hager_Domovea/' crumbLabel="Domovea" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "domovea-smarthome-steuerung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#domovea-smarthome-steuerung",
        "aria-label": "domovea smarthome steuerung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domovea Smarthome-Steuerung`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#access-the-domovea-smarthome-control"
        }}>{`Access the Domovea SmartHome Control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-instar-camera"
        }}>{`Adding your INSTAR Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#hd-720p-camera-models"
            }}>{`HD (720p) Camera Models`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#full-hd-1080p-camera-models"
            }}>{`Full HD (1080p) Camera Models`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#domovea-smartphone-app"
        }}>{`Domovea Smartphone App`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "access-the-domovea-smarthome-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-the-domovea-smarthome-control",
        "aria-label": "access the domovea smarthome control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access the Domovea SmartHome Control`}</h2>
    <p>{`To access the Domovea Basic or Expert server it is recommended to use the `}<a parentName="p" {...{
        "href": "https://www.hager.de/neuheiten/markteinfuehrungen-febr.-2019/domovea/969046.htm?Suchbegriffe=domovea#Inbetriebnahme"
      }}>{`Hager Pilot App`}</a>{` for Android or iOS. Alternatively, you can search for the IP address the server received inside your router:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/029b898fe6f6cf08028fe28fee000cd1/36c33/hager_domovea_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQ0lEQVQY023Oy0rDQBTG8QgquHVjtYhd+QIWV658HhXFhaBLtVbcCNWXsaUXxYULxZ1JelGoQi8Wk9QmzSSTv2RaQdSBH+c7Z5jhaOsXj6ydP7B8eEf67J7ZvRu0jSITWyW03zZLzOxWSBzcMrVTYXqnwtz+LZPbZXWnrF42iKVzdVZydZayJvPHOgsZQ0mO67fFE4PUqUnyWCeZ0UmdGionjp5IZQ20cs2hWLUpVR2Kps2VblEwbQqmQ94Y9XnDUrkQ94ZNqeFSefYoN1yVy88j1y8emvQHEA4h8EB4KkfBSJxlPJf+SOgj3D5IAUj+O5rl9PFFQBRBBPifA1q6QadaY9juELz3EN13RKeLsGx6jkez/UHPHhDK6A/NdV08b4j6EQhtG0vXcWp1grc3xOurqkGziWi1CMIQGZOSaPzm54ZfcdGQP8IX/+UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/029b898fe6f6cf08028fe28fee000cd1/e4706/hager_domovea_00.avif 230w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/d1af7/hager_domovea_00.avif 460w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/7f308/hager_domovea_00.avif 920w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/c82bd/hager_domovea_00.avif 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/029b898fe6f6cf08028fe28fee000cd1/a0b58/hager_domovea_00.webp 230w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/bc10c/hager_domovea_00.webp 460w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/966d8/hager_domovea_00.webp 920w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/30833/hager_domovea_00.webp 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/029b898fe6f6cf08028fe28fee000cd1/81c8e/hager_domovea_00.png 230w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/08a84/hager_domovea_00.png 460w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/c0255/hager_domovea_00.png 920w", "/en/static/029b898fe6f6cf08028fe28fee000cd1/36c33/hager_domovea_00.png 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/029b898fe6f6cf08028fe28fee000cd1/c0255/hager_domovea_00.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And connect to the Domovea web user interface using  your web browser. The `}<strong parentName="p">{`default login`}</strong>{` is `}<strong parentName="p">{`admin / 1234`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/6bfd0/hager_domovea_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGklEQVQoz5WQTUvDQBCG+2/F2nZp6Xes9iaIePFvKZ48p4IGTFFIskmT/XpkU6xJDxYHXnZnZ+bZmelM50umi4BFsGI8W3AzGvMwmXErhtyLIUFvwFlfcNEXnPcGdHuCrj/7gsFwxGS5Zn51R7Bac3m9psORWWcxxmCtreWcw/m7MYDb+w0dW6cZqKqKLMtq30O9SuP4kgXbJKPQ9vDuP5NSstvt6tofTgtYliVpmh4SfFGSSSpj0Q6U+e3amwcWRfE/YN2BUaArrNbYRn6e56eBfuSmGet42aY8vX8iS9WKnQT6HSZJUu9IKYXRmrxSBM8x4vGDSJZgNJWPGVNP8yfQjxjHMWEYHrQJQ942e71uwlYsiiK01i3gNzTkUjtgIbQrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5682d5572ade18e6cf719cf1a1e0bba0/e4706/hager_domovea_01.avif 230w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/d1af7/hager_domovea_01.avif 460w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/7f308/hager_domovea_01.avif 920w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/ecfe0/hager_domovea_01.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5682d5572ade18e6cf719cf1a1e0bba0/a0b58/hager_domovea_01.webp 230w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/bc10c/hager_domovea_01.webp 460w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/966d8/hager_domovea_01.webp 920w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/7ed23/hager_domovea_01.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5682d5572ade18e6cf719cf1a1e0bba0/81c8e/hager_domovea_01.png 230w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/08a84/hager_domovea_01.png 460w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/c0255/hager_domovea_01.png 920w", "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/6bfd0/hager_domovea_01.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5682d5572ade18e6cf719cf1a1e0bba0/c0255/hager_domovea_01.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you haven't set up the server yet, please choose to add a `}<strong parentName="p">{`New Project`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "864px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5074cd69c35315469c6d3f86d853c381/9cab2/hager_domovea_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACF0lEQVQoz4WP3UtTYRzH9y/0smVIEfQfRBGilIV0sYqKVaYVEqPddZVHz3OOz3POwIs2t7GBBBYEtp3jcedtvch29kwRLCeptN1IIb3clOBZm9OdVbPtxKYXo6A+fPhe/T4XP8ueY/a9xy/uO3Fp/8nL1rYr1jaHtf2areO67VS37XTPgc6bLWdutZy9fbCr79C5viN259ELrsPnXa32u612l8XtZhmEIBxCEDII/VsEIRyqX7pZ1s2yFgjhiNcbCoUCgYDnf/j9/mAw6PP5IIQMw1homh5/GuEnZUmJLS4tZ7OZTCbTmD/JZrOp1PRUPK6oKk1R9RgAEIlwk1ERY/xt01jLFb/qG7liyTTNWpPVWs00zYV0WlVVSZYBAPWYJEme51RVTaVwaauc1zcKueJ3Y7tS/tXsT2O7WjGn516F5ZgkK4Akd2OO5wVB0BKJ2S/G+EpOeJ+XP5ejn4xm+Q+lufUKq850eMNjvAgpABFqxBwniqKmaYHVrTtL+XtvC87FnGNevzqvd6f13gW9J607Xq8Pv9scjs10jkQeT0g0ICFElkGS5DlOlqQkxmYT1fqP5ou1HzfeFEY/Gs7lgme1/ODZbLsn/GhC3I0bP/OKogiCkEgmNYx3TGCMU5h/qQXl+JPniVEl3s9N9T6MdoXEMUEa2okBABBChBBFUf1/MUgQ9ABBDtSXIIj7JEUjhkEIAIAQ+g0sQpoczWYasgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5074cd69c35315469c6d3f86d853c381/e4706/hager_domovea_03.avif 230w", "/en/static/5074cd69c35315469c6d3f86d853c381/d1af7/hager_domovea_03.avif 460w", "/en/static/5074cd69c35315469c6d3f86d853c381/2bbfa/hager_domovea_03.avif 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5074cd69c35315469c6d3f86d853c381/a0b58/hager_domovea_03.webp 230w", "/en/static/5074cd69c35315469c6d3f86d853c381/bc10c/hager_domovea_03.webp 460w", "/en/static/5074cd69c35315469c6d3f86d853c381/b4e26/hager_domovea_03.webp 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5074cd69c35315469c6d3f86d853c381/81c8e/hager_domovea_03.png 230w", "/en/static/5074cd69c35315469c6d3f86d853c381/08a84/hager_domovea_03.png 460w", "/en/static/5074cd69c35315469c6d3f86d853c381/9cab2/hager_domovea_03.png 864w"],
              "sizes": "(max-width: 864px) 100vw, 864px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5074cd69c35315469c6d3f86d853c381/9cab2/hager_domovea_03.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose a name for the project and click on `}<strong parentName="p">{`Create`}</strong>{`.`}</p>
    <br /><br /><br />
    <h2 {...{
      "id": "adding-your-instar-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-instar-camera",
        "aria-label": "adding your instar camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your INSTAR Camera`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "863px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6d07d179e895e7483225f2ebbe77517f/ee455/hager_domovea_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz2WRzUsbQRiH948Sj7ZnrVVJvLRo1VNPPXjwVj9ApEirWBEtirZq0gbFCKUoymJK/UAlupQepFSlOfgRxFaT3Znd2c1TdlI16sDDvDMv7++d9zdG5ctpqjriPOpKUN09w+OeOWpezVPb+5na11+o61skMrBEdNAkOrRC/fBX6t+t8nRsjcb3mzz/ZPEstktDzOJJ/DsG16twhwAKAfgern2Ja+d0TMEv5gJVRJ9vMISU3ENIlPJ0G18pHEdgOwICX9+5rosAjQxjeYMRJkuRUmqRk/McE8sWmexffF9RCHys/SNipkVeeqh0Gnd7G1eIYq2UejdCgVLyjqNfsfHjEKOmn57Z9WtTOj9+w4i8ZWsvA60vkM0tuBcXSKWQQuj6W4LhqJ6UZC8dxld/8bBrlrbJFHuZUw7O8nQm01S0Jxha2OH4T47QFBFO9V9MCwohuEL75Hts7Gep6E/RPJqivG2KweQaH7Z+8+CNSdOISVnrBObOT/0ptu1QqvEPY/ntY3X0y5gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d07d179e895e7483225f2ebbe77517f/e4706/hager_domovea_04.avif 230w", "/en/static/6d07d179e895e7483225f2ebbe77517f/d1af7/hager_domovea_04.avif 460w", "/en/static/6d07d179e895e7483225f2ebbe77517f/f15cd/hager_domovea_04.avif 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6d07d179e895e7483225f2ebbe77517f/a0b58/hager_domovea_04.webp 230w", "/en/static/6d07d179e895e7483225f2ebbe77517f/bc10c/hager_domovea_04.webp 460w", "/en/static/6d07d179e895e7483225f2ebbe77517f/8e594/hager_domovea_04.webp 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d07d179e895e7483225f2ebbe77517f/81c8e/hager_domovea_04.png 230w", "/en/static/6d07d179e895e7483225f2ebbe77517f/08a84/hager_domovea_04.png 460w", "/en/static/6d07d179e895e7483225f2ebbe77517f/ee455/hager_domovea_04.png 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6d07d179e895e7483225f2ebbe77517f/ee455/hager_domovea_04.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To add your INSTAR camera open the `}<strong parentName="p">{`Domovea`}</strong>{` configuration tool.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "861px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd4151bba3e4ad76712b958cb6cbd316/e35ec/hager_domovea_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZ0lEQVQY06WQzSvDARzGf5qTo4t/wU2JgyK7ODjJGpu2ySZvua9IZmEzMkvy8vPys9GakdoLm5fYvGtopcTFwdHRW4n9PprR3D311Len5/s5PILGtkJtv4/aQT8q+xqqoXXUwwHqRkOonRvUuSLUj2+hndhFO7mHdipGw8wBurljdAun6KRz9O4EjcuXmHxJBNL6/ODt+Yn31xdA5j/6BkZuHjG4E2hnDmmejWEPJekJ36ARj2gU43T5zrBEbtFL53QsndIxH2dk545W7xVN0gkmMUbg4j4LHIjeIhg8KEwSQrWD4m4/BZYdcluXEWrGyDdOU2iNIhg95BhEhOohKhzbKNpXyGtZRKjsp8d7lAW6T+4p6QtSZQtS1rtK29w+LqUelVmidCCIxhlGLx5QbttAaQuitPjp9BxTYY9QNbJJkdmLe+86A5Rl+Wc3GdJ3KpUZw9oLDw+ZRVMp0r3v7q9/fv5m6eQLeUNz9p+98jIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd4151bba3e4ad76712b958cb6cbd316/e4706/hager_domovea_05.avif 230w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/d1af7/hager_domovea_05.avif 460w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/9e861/hager_domovea_05.avif 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd4151bba3e4ad76712b958cb6cbd316/a0b58/hager_domovea_05.webp 230w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/bc10c/hager_domovea_05.webp 460w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/16d1d/hager_domovea_05.webp 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd4151bba3e4ad76712b958cb6cbd316/81c8e/hager_domovea_05.png 230w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/08a84/hager_domovea_05.png 460w", "/en/static/bd4151bba3e4ad76712b958cb6cbd316/e35ec/hager_domovea_05.png 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd4151bba3e4ad76712b958cb6cbd316/e35ec/hager_domovea_05.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Devices`}</strong>{` to open the list of installed devices.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "865px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/562f85b14da30d37b8129509463372ca/79e48/hager_domovea_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwElEQVQoz3WRy2oUQRSG5xEENd5vZOMb+ACSVwgogqALEQQVotkJggt1IYKCCEJAEQQhuAtoVFBRkBF0YTAYLxnHyUynp3umu6qnbl2fdIWJCZoD5/z8pzj/uVRt25HrbBq/xuajN9hy7CZbj99i5MQdRk7eZfupe+w4PcXOM/fZdfYhu88/Ys/EY/ZOTrPv4jT7J59w4NIMo5efMnrlOQevvqKWZJKFZof63Hc+zi/SjFK6mWS+0eHTQpNvrZhOmvHjd5ufrYiluEdiPN2pB8TnJvi81GcuLmj0ChKhqFGZL7FGY7XCGYN3hnXmSwopcEZD6QJH5NCNscagtMZXeaDmPchChc5yoEOyLEv6mSROM3q5xDlHnguEkEgpEUKgrEVqQ5IkLEdReA+CVbj9JWXsWYPxN23GXrYYe9Hi8GyTQzOLXPgQVSOijMFa+9crbkxoNvRVwV+F421b8LrZ491yQT3R1FPD+0TzVdiwjjFmnQ+F1/KVlavoLFnaxyk1PNoKOIs3JpxgI5EhXxWswkBpoiRDKoO2DmVscKl0wHLNhFWhUorBYLCxoPceY23A/1l1n2FRhdWn5HmO1vofwT8KQZDoFalo8gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/562f85b14da30d37b8129509463372ca/e4706/hager_domovea_06.avif 230w", "/en/static/562f85b14da30d37b8129509463372ca/d1af7/hager_domovea_06.avif 460w", "/en/static/562f85b14da30d37b8129509463372ca/d965f/hager_domovea_06.avif 865w"],
              "sizes": "(max-width: 865px) 100vw, 865px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/562f85b14da30d37b8129509463372ca/a0b58/hager_domovea_06.webp 230w", "/en/static/562f85b14da30d37b8129509463372ca/bc10c/hager_domovea_06.webp 460w", "/en/static/562f85b14da30d37b8129509463372ca/5d624/hager_domovea_06.webp 865w"],
              "sizes": "(max-width: 865px) 100vw, 865px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/562f85b14da30d37b8129509463372ca/81c8e/hager_domovea_06.png 230w", "/en/static/562f85b14da30d37b8129509463372ca/08a84/hager_domovea_06.png 460w", "/en/static/562f85b14da30d37b8129509463372ca/79e48/hager_domovea_06.png 865w"],
              "sizes": "(max-width: 865px) 100vw, 865px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/562f85b14da30d37b8129509463372ca/79e48/hager_domovea_06.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`New Device`}</strong>{` button to add your INSTAR camera.`}</p>
    <br /><br />
    <h3 {...{
      "id": "hd-720p-camera-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hd-720p-camera-models",
        "aria-label": "hd 720p camera models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HD (720p) Camera Models`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "860px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd78739d21b90537807599d0d70b6cc7/914ae/hager_domovea_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCElEQVQ4y5WTT4vTQBjG+wUVL3oQBD35CTwtwl4Wv4cXwUstartIS3dhoW0qi7K7dhHFg+62afNvJpP5k5nkkZmkZWPbgwMPT5i87y/vO/Om1fnQRcYFwpgiiCiKosD/rCzjiEkKISTevnuP1unJEBFJ4QcxWMaRUOZEKEPKJW5S5fQnVViwHEIb8NyAawOhCwhTQJoCKtf4dHyMVr/fdyA/iMCFgNhIQimFMCFYhTFWUaUoTrAKQgRRhNDuhSG4kK6zj91uBbTJNimMCaSsQGstfR/z21v4iwXm8zkIIWCMOaWUglKKXCkH7FrgYNAH5wLLIHa+BlrX2qAsSxRlufF9yxjTBK6CGFLIOy0LaK23EssaflcNoGtZKcSU2fBGclEWewF7gcOTIW5+/YZ39hnfL64x+/IN119nmJ1fgiW0kYx1y/VzWb9rAMejM7x53cGjZ4d4/PwI95+8xIOnh7j38AUuz6+qSk1d6Rpe97KzwsFgAEJSLPwVKKHIUgZGUyed59vntobtBdozlHIz2CrPIe0tK+WCdq6DA+Dnj+pj9Z/VuGXGMiyDqHHDVnZ8YsJA0sw5ZRyESyRHr5BcXEG54zBuBu1EbFq2Vdtp3zVlQmkoXSATuXOZG2QAuNmO7vV6aHU6HYzHY3ie5/xfTT0PnjfBdFq5jZu6/Qkmk8kmbjQaod1u4y/+lhpe27H4sAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd78739d21b90537807599d0d70b6cc7/e4706/hager_domovea_07.avif 230w", "/en/static/cd78739d21b90537807599d0d70b6cc7/d1af7/hager_domovea_07.avif 460w", "/en/static/cd78739d21b90537807599d0d70b6cc7/384d4/hager_domovea_07.avif 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd78739d21b90537807599d0d70b6cc7/a0b58/hager_domovea_07.webp 230w", "/en/static/cd78739d21b90537807599d0d70b6cc7/bc10c/hager_domovea_07.webp 460w", "/en/static/cd78739d21b90537807599d0d70b6cc7/31b17/hager_domovea_07.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd78739d21b90537807599d0d70b6cc7/81c8e/hager_domovea_07.png 230w", "/en/static/cd78739d21b90537807599d0d70b6cc7/08a84/hager_domovea_07.png 460w", "/en/static/cd78739d21b90537807599d0d70b6cc7/914ae/hager_domovea_07.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd78739d21b90537807599d0d70b6cc7/914ae/hager_domovea_07.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<strong parentName="p">{`IP Camera`}</strong>{` and for `}<strong parentName="p">{`HD (720p)`}</strong>{` camera models click on `}<strong parentName="p">{`Discover Cameras`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "863px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/46a53026920650eaf21166207c28d831/ee455/hager_domovea_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACIklEQVQ4y5WSvW4TQRSF/Uw8Ai9AQ0MBHS1NOgpoKKBCICGQQkFDYzteEqwQUIgigSAyIQQJHJwG4oCigO317s7O/8xBd3Ztr0lAYqSjndHsfLrnnls7c/4Klta30H61g+XNbay9/YiX7z6V+oyN7W6h93uFdr4U+tALWut08azTxWqni7MLd1CLWkvgXGLMBOI0h3Me/7Ok1MhyAa012k+XUWs2GhiMxjgexuBcIEkZ4iTDOMmQcYE+0zjIFL5mCt+ZRq5NRRbcOGgPKK3RiiLUGo0GMsbwa5Qg5xxSyqmMMRBCIM9zMMbCf957kIcgD/zgBqnxsMbMgIzlATiIEwghoZSaAjnnAZamKbIsC8Bi+bDv5waJ8XBV4KTCGewkkGCnAQ8JaAHn/QyYZgw/h+NgmfpIoj01miz/s0JukQgDF8cFsF6vQ0kJLsQpGfoAph4SjKDOufLKwzuHvgWSvX24WzfRajZRW3kS4c1uD7cfP8ditIl79XXcb26Ec+/bEZzVATaxPQes9tDaosLV9grqL7Zw6fpDXL7xCBeuPsDFa4s4t3AXr3f3ATikZf+oQmvtHPDwz1DIspTFYNLjE5YnI/MX4MG0QjMfyvEgDt8ikFkoSkkICiuAK5YD0+OIG7ATY1OmTIlWB5uAg3GGUZpjmDAkTIArA6k0hNKwBC9TpxGLJpYpYSFVANDFRGSPHittwLiEkBraOmhjg1xpuwr8DbMMElwAC2DJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46a53026920650eaf21166207c28d831/e4706/hager_domovea_08.avif 230w", "/en/static/46a53026920650eaf21166207c28d831/d1af7/hager_domovea_08.avif 460w", "/en/static/46a53026920650eaf21166207c28d831/f15cd/hager_domovea_08.avif 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/46a53026920650eaf21166207c28d831/a0b58/hager_domovea_08.webp 230w", "/en/static/46a53026920650eaf21166207c28d831/bc10c/hager_domovea_08.webp 460w", "/en/static/46a53026920650eaf21166207c28d831/8e594/hager_domovea_08.webp 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46a53026920650eaf21166207c28d831/81c8e/hager_domovea_08.png 230w", "/en/static/46a53026920650eaf21166207c28d831/08a84/hager_domovea_08.png 460w", "/en/static/46a53026920650eaf21166207c28d831/ee455/hager_domovea_08.png 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/46a53026920650eaf21166207c28d831/ee455/hager_domovea_08.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The list will now be populated with all IP cameras on your network that support the ONVIF service. Choose your `}<strong parentName="p">{`HD (720p)`}</strong>{` INSTAR camera and click on `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "863px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90e47ae3cfee2e0654c997f539f7285d/ee455/hager_domovea_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABp0lEQVQoz42Sz24TMRDG8xDcuPMCiBPvxpFX4MgBARIX4IQAtYCqXii0AkFom9CwhObfZjep1x7bM54Z5GyLUiE1/fTdxr+Z+Wx3fhTTg97p7rfBu4Pj7f3e/s/Rbrf48OXk49Gfr8PqUzHf6Zdbx7Odk/l2v3zdK7+PlkeTs8PJ2eHUdFRVmAljDCCJVESYhUmFUbROmoQVg4ag+ajomjqJGWNEcMGD9+DBL6q6LGfGmIA0rpe1ccNxtVg2RClGDJiNiRNLnowsM0+JRTU7a9U4Ek0N9Efzew+ev9/rMqG1DgCcc4iYJ6vq04G5+XJ4d2t8+83oztvxrVenN14M73eXqjoPKXBeVlRZZGXFxDHGc7hv8Elhn/22j35lPy7sw0Hzuc7l0rjK2EDc+BBZkBVZA3H4B18hcDYRqaoHiCG0gZjXJoso/+c2NzjXNA0R+eCZue14Cb5CtrEAIKoA4L1PiZiZiK4FO+eI8pv71Q2nlTbDbbyqquqqWnic+LT+R9rqprVtDiyqjYPEfP5aF+E3wtY5l2/O+4CURFpfC5aL/yaX3cJ/AW+gJYn4f/+eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90e47ae3cfee2e0654c997f539f7285d/e4706/hager_domovea_09.avif 230w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/d1af7/hager_domovea_09.avif 460w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/f15cd/hager_domovea_09.avif 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90e47ae3cfee2e0654c997f539f7285d/a0b58/hager_domovea_09.webp 230w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/bc10c/hager_domovea_09.webp 460w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/8e594/hager_domovea_09.webp 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90e47ae3cfee2e0654c997f539f7285d/81c8e/hager_domovea_09.png 230w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/08a84/hager_domovea_09.png 460w", "/en/static/90e47ae3cfee2e0654c997f539f7285d/ee455/hager_domovea_09.png 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90e47ae3cfee2e0654c997f539f7285d/ee455/hager_domovea_09.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Depending on wether you activated the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/ONVIF/"
      }}>{`ONVIF Athentication`}</a>{` inside your camera's WebUI or not you might have to add your admin login here. Afterwards click on `}<strong parentName="p">{`Test`}</strong>{` to verify that everything is working as expected.`}</p>
    <br /><br />
    <h3 {...{
      "id": "full-hd-1080p-camera-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#full-hd-1080p-camera-models",
        "aria-label": "full hd 1080p camera models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full HD (1080p) Camera Models`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "863px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c75a373ab7908fd691be15481bf9fe43/ee455/hager_domovea_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtklEQVQoz42Sy24UMRBF83msWCAWiF0UPoEv4DfYsGTNkhUJhAQGpERieEkzNJGSmZ60J532o+vhqkLujsIIUMjVlTf29bGramt25qbV8v3nn6+PZ9Oq/lG3776eHEyro9ni26I9rNx+5Q4q9+nsYr7qZqtufu6v3IQtMxMRJoQUc2YzU8kmbCYotkIlEWMwACt7ahvayiJIlGJIKcVU5Ny6rlftZQfEy3W77sL3arFyLTH3gIAESMQ5ixYyiTrIJJpFWfT6dmCuQz9buCdPX+x9/KJMMcS+QCIRFbKZ9YD1pW9C7yKeRwRiJM4ionoBAjK810xURU3UKAsiXoUVgWOXEYVIiUzZLA+HrfGx6UJP2SdAURIjMWCB6/DLU39v9/TR28XD/fru3vL+m/rO7vLZvDOzEELmUsU+pZE2Fvg3+VWdtifN4+P1zsRtT9zOB/fgsHl+Eko4Ru89MUMB6z/CNyh436ekZjGWVvBQCmbe+PNQhj88YkIISKQijJgHici43kQeO9a4Zu2aFngJ+a8Z+d+zUxm7rGY+prF5xQP2Nn8OMcZhFoDKNTr6VmEdNA7JpsfwL9K9JN+Is6IyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c75a373ab7908fd691be15481bf9fe43/e4706/hager_domovea_15.avif 230w", "/en/static/c75a373ab7908fd691be15481bf9fe43/d1af7/hager_domovea_15.avif 460w", "/en/static/c75a373ab7908fd691be15481bf9fe43/f15cd/hager_domovea_15.avif 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c75a373ab7908fd691be15481bf9fe43/a0b58/hager_domovea_15.webp 230w", "/en/static/c75a373ab7908fd691be15481bf9fe43/bc10c/hager_domovea_15.webp 460w", "/en/static/c75a373ab7908fd691be15481bf9fe43/8e594/hager_domovea_15.webp 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c75a373ab7908fd691be15481bf9fe43/81c8e/hager_domovea_15.png 230w", "/en/static/c75a373ab7908fd691be15481bf9fe43/08a84/hager_domovea_15.png 460w", "/en/static/c75a373ab7908fd691be15481bf9fe43/ee455/hager_domovea_15.png 863w"],
              "sizes": "(max-width: 863px) 100vw, 863px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c75a373ab7908fd691be15481bf9fe43/ee455/hager_domovea_15.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you are adding an INSTAR `}<strong parentName="p">{`Full HD (1080p)`}</strong>{`, instead of clicking on `}<a parentName="p" {...{
        "href": "#hd-720p-camera-models"
      }}>{`Discover Cameras`}</a>{`, click on `}<strong parentName="p">{`Generic`}</strong>{`. Fill out the following window with the camera's IP address, port and admin login. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "862px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a0276ca21a0e12d308df8c388243e983/f0551/hager_domovea_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACKklEQVQ4y5WSS2tUQRCF8zPcC7qT+GREUCP4b1QEUTcuBFEREUVcRIkoBKPoRjEmvhBFsshSFwEhRhInYx4zcx/dfW8/7r2fdGfmkqhBUnCoWnSdOnWqBxqn77H7xDCDJ+8yeGqEfWcesv/cKHvOjrL3/CMOXHhG4/I4jYvPaVx6ycErExy69obD199x5MYHjt76yNDtzxy7M8Xx4WkGAIyxdKKUxaV2qH10E8FqJJBRgvvZpKkKFqWhFSuy3LAW1V8IhNY6hJSkaUqW5VhryZRCaI1ptdBPntLKHLm1UDqvgM0iEDrn0HmONaaGJ+92u6gsw2teiVKWuimTU1+ZnW/hnCXPc7TWIXuOmtAUJb+UI3MluStDrtZNNdbS7CR8m29x8/FbPn2ZpbQGKRVZlqGUClvVhH5CFCd0RUakNB2l0cZijKknt1dXEXEU6sRVUFUbhgZHq56HRueoNEGoHJVrZK6pnAVnqYoiPBZChAGeKJMyDCrLkqIoAjxZrXBsLmHH+AJD75vser3I9ldNdk402fZigaszcXgYp2nw1Tf6NT3sOtINR5lJDCNzgrEFxf05EfDgh2D4u2C6o9cUShk866uN4zh417el9rCsKv4X/oWSMpAUZRmaPZHPHl6hv3at0JO6sqLo5fUoegP9UdrtTu3Vn8cwvb85sKmqXmM/L68skwoRaillWLuf+6tviVCINDT79ZIkCev3c/0DtkLYN77sefgveMLfcB4d2GjP2OYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0276ca21a0e12d308df8c388243e983/e4706/hager_domovea_16.avif 230w", "/en/static/a0276ca21a0e12d308df8c388243e983/d1af7/hager_domovea_16.avif 460w", "/en/static/a0276ca21a0e12d308df8c388243e983/30a87/hager_domovea_16.avif 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a0276ca21a0e12d308df8c388243e983/a0b58/hager_domovea_16.webp 230w", "/en/static/a0276ca21a0e12d308df8c388243e983/bc10c/hager_domovea_16.webp 460w", "/en/static/a0276ca21a0e12d308df8c388243e983/e32b8/hager_domovea_16.webp 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0276ca21a0e12d308df8c388243e983/81c8e/hager_domovea_16.png 230w", "/en/static/a0276ca21a0e12d308df8c388243e983/08a84/hager_domovea_16.png 460w", "/en/static/a0276ca21a0e12d308df8c388243e983/f0551/hager_domovea_16.png 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a0276ca21a0e12d308df8c388243e983/f0551/hager_domovea_16.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then scroll down to additionally add the `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/"
      }}>{`Snapshot and Video path`}</a>{`. INSTAR Full HD cameras offer 3 snapshot and video URLs in descending resolution: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmpfs/snap.jpg`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmpfs/auto.jpg`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmpfs/auto2.jpg`}</code>{` and
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/mjpegstream.cgi?-chn=11`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/mjpegstream.cgi?-chn=12`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/mjpegstream.cgi?-chn=13`}</code>{`. It is recommended to choose the smallest, sensible resolution here to reduce the bandwidth requirements. Especially in a multi camera setup.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "861px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/e35ec/hager_domovea_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRklEQVQ4y5WTbWxTZRSAr4lSpjIck8UayhAQiIOxdh2sG2Y4TBYcA+aSRk2MIiHMLFESjMZ/uMR9xK84JixkKmvX3pa2t7Qos2s7C2wxRmXtjyXofvhTxlrXbr29d9rkMb1N2PynJznve96ck+c9OR/CziNvsrnlFLs7zuCN/sjYVJxvp+JEfpohdvsO39++Q2z6V2LTvxGLF3SWG4lZYolZpmZ+x3srjqmzj6qTH1LT2Y/glyQcThGP14eqKCxmc2Rlhf8q+b9UrgUDeD0eJJ8PQZIkRNGF1+djaWmJVCrF3Nwcf9y9y9y9eyRTKeaTKZLJFPPzyft2aiHNn7LKwkKaQDCIx+vFVwAWDpfLhd/vR5ZlDZpOp8lkMpqdy8nkZFnzZbNZ5Gy2+M7lUP7Os5jJaMm43e4isJCm03WFUV+ArCyjLi+jqirLqqrdi7JCJqugqgo5RUFRi2XJ5XKafyGdxuPxrAJKEjabjetjY4yMXKa6aheWfWbMtUbMJiOtTWY6njdhMhlpPlCHxWzk6KF9NNbXsr+mCsnjJhyJ/Btot40wEQ7T39eLIAjoBAHzEwJddWvwv6jDuv0BntEJHDMIVK0XeHytwBpB0GKHvjhPdGJCK5sG/C7g58SnX2IZDNLd04u+fC2H9m/DWm/gaHUZbzVsZOj4Vr56qZT3W0por3uMTRUPs6FUVwQOXSQaja4Ag36JvmE7PVejnOvuZu/uSt7p6uDcKw0Mvmrks6Zyxq2bebetgrZn19FqKeflZj1HGvVUb3mI4UsXNKDD4SgCCx3yul388sMkn3z8kfZrU+1WBl+rwddeyjfWR/m6azufnz3I6bYtvHF4I6daK2hvWM/eSoHLwxeJRKOMjo6ujI1TFLlx89Z94A7DI5w/s4kLp5/E1VVJ/0k9A2d3YHvvad7uMPD6YQOdxw0c3PMgXsclwpEJHI5VQFEUmZycpK+3RwMWtLG5jOcsJXxgLWfg2DpOHNDRUlPCUxsE9hgEXqgvY5deYHhogFBoHMfqDAstD4VCJBIJbXTsdjtXgyKRcZFYyMXMTYmfw27C10TGAldwiXYkrxPR6WA6HteWwul0rgALgxkMBsnn8/xfURQF36pN+QdtihC7rCDOMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/e4706/hager_domovea_17.avif 230w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/d1af7/hager_domovea_17.avif 460w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/9e861/hager_domovea_17.avif 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/a0b58/hager_domovea_17.webp 230w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/bc10c/hager_domovea_17.webp 460w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/16d1d/hager_domovea_17.webp 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/81c8e/hager_domovea_17.png 230w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/08a84/hager_domovea_17.png 460w", "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/e35ec/hager_domovea_17.png 861w"],
              "sizes": "(max-width: 861px) 100vw, 861px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b56b8b7ad8e1e5e86084fdcc23fa26c/e35ec/hager_domovea_17.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on `}<strong parentName="p">{`Test`}</strong>{` should now show you your camera's live video.`}</p>
    <br /><br /><br />
    <h2 {...{
      "id": "domovea-smartphone-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#domovea-smartphone-app",
        "aria-label": "domovea smartphone app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domovea Smartphone App`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/a878e/hager_domovea_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADR0lEQVQ4y0WS6XLiRhSFRWxss5kdtLUESAgQiwQIsYnFxvZ4yvmXl0vGqRpPHicZl50n+lKSpyY/Tt3qvl+fU327pZYpaLUMhNBQlCaq2kQIFcPQMAwd80c/VryO+7F0/X8mriKWUJFisNNpJQcUpYEs19G0JpouI4Ty0/CDMROzD0ZG/8EIQ/1QbKhrCq2WoNu10IXyARlqkty2TMyWSMCYcRwbXagoci0JjffbHZO2ZdCxzMRDMoSKZRlJU9eVJDG+Qq/v4IwczLag2axiCAVZqdM2NXq+jzObMXL7CdMddXFcm90uQjJNDcfpUC4XUZUGnZZOp2UwcPsMfRfLtqjXy+hak1w+i6bKjMcurtvH8yf0xz28+ZS+22MbbZAa9TJCyGhqDU3XyFXqNISBO3Tx5l4yP9NQCQKfyWTAZOLS73XptE38qU+wnDNbzBl7I6LdFqlcKmAYKr2ehapppIp1CnWF4WjI2HM/XlpoHA8R2+2KMJgy9UaEiznH44H9zY6HxzuCxYzVOkTKZi4QusJms0bRBelMnpqs4vs+YTin025RKhbo2m32u4jxYknbcXDsDsswZBut2W7XLBYBy1WAdHlxRqNeoVKpkMlckUpJNBoVVsslu902+Z/psxTxTaqlIteiTUU3KBaumE19dtGGIJgRLgKO+x1SNZemep3h4uKc9C8SkiRxnU/jOC1WmyWy0qScOadWuCJ7fY0+GNMeebjhCv9wS7iLkvAwCNjv90jFqxS1wiWq0CipOrlqg5oQdH2P+4dP2LZF4TJFJXuOJjQalkOj00PrDbH9OaeHT9zeHDns9zw+PiKlz88o5DMI26EfnZidPjO//5Xw6TfW24hqpZyMIZ/PoqsyucwlVxcXlIpFfM9jvV4l84vr7emENHYHuH2HgW2hVUt01Caz0ZCbaMvpsGc6HjMaDBj2HVynS0uV6Ro6oe9xOh64Ox5YzWeMnC6iWUeyw4huuMMKNpheQH8ZMbt9ZHH/hDqYoI9mOMtdwtg/GGu2xNvfEd4/YU7mZGVBpqlzXm0ipb58J9HzK9mvbxS+/Uvl2zuFl3ekL9+Rnl9/6uz5ldzXN/Iv7xRf3qj/9U76z1ek3/9G+uOfhP8P0Bm8v5O9TNYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/e4706/hager_domovea_18.avif 230w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/d1af7/hager_domovea_18.avif 460w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/7f308/hager_domovea_18.avif 920w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/e1c99/hager_domovea_18.avif 1380w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/76ea5/hager_domovea_18.avif 1840w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/95b77/hager_domovea_18.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/a0b58/hager_domovea_18.webp 230w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/bc10c/hager_domovea_18.webp 460w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/966d8/hager_domovea_18.webp 920w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/445df/hager_domovea_18.webp 1380w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/78de1/hager_domovea_18.webp 1840w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/8df42/hager_domovea_18.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/81c8e/hager_domovea_18.png 230w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/08a84/hager_domovea_18.png 460w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/c0255/hager_domovea_18.png 920w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/b1001/hager_domovea_18.png 1380w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/161ec/hager_domovea_18.png 1840w", "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/a878e/hager_domovea_18.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce1d46c15e0f3d59628ef9e4668faaa7/c0255/hager_domovea_18.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now install the Domovea app for `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.hager.domoveav2&hl=en"
      }}>{`Android`}</a>{` or `}<a parentName="p" {...{
        "href": "https://apps.apple.com/de/app/domovea/id1363767562"
      }}>{`iOS`}</a>{` and login to the server.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6ec2b04c051a5fbd928e9524649bc21/a878e/hager_domovea_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgklEQVQ4yzWUyW4iVxSGL9hMZqyCgoKiJmpgNrMZbWy327S7cRubdCalLbU7i6ySbLLqR8guyiZ5hrzjF1FRFkf36lzp1z+cc4Xvu5imjmFUMQ0d2zZx3RqWZVCplKhUilT1MrpeQdPKGIaGaVaDOtx1Q6NarWDbBo5jI/y6F4BVq2U0rYSul3FdG7/uBiBVTQ16mqZimgZe3aXmm7j1Gn6jRqfdZDabsljMOT9fIWo1C13XUFUFpZBDOzDSSmSzqYBFpazg1ExaTZ/+oE9v3MPr+LT6LfyWw3Q24f3DA9vtlvv7e8R4MsLznICyaWrYVpVSSSEUCgWSGnUH17HpdtuMRkP8pofn2ViOg1ws4rkWw0GPdrvFZrNBXF9esFzMmExGTM9GtFt+ILGoyNRsg+urCxQljxCCdFQwqkb5dZ7iQyPKXU1Qyx8RDYvgfTgaIVrnr6haJiVFJi9nAsmWpVFWZSxLR6uo5GWJo3CIzbrLl/2AP16X+Huv8XIjczOSGXgZQkIwHk8Q8/0PuK0WipxDyqXRtCJ+3cH3/kva0Cvk81LAwFDT/PnZ4Z9nib9+afDlucbnuxLPrwukIoL+YIQ47Z7SbrTISzlOElHUkkyvd8piuQzGpJCXyGYzHB8fk0rGWK4UftpW+G2rcndWYODluBypyJkY09kccQjgMEeylCMWi1BUJOp1j2azSTqdQoQE8UQUrVKm1+txdjZhMOwz6J/S7XSYzadcrNeszs/Z7XYIw9QDSelUnOhxiIKUIpfLBhIT8VhwJk8iTCYDFosZm9vXPD3t2D898fLpE/fv3rFeLfj+u2/Y7/eIRDLBUeSIk2iYYjqBlIyQy2aIxaLEY1HCB4axMJ1OHdevMV+OeXja8dV+z48vL2wPgOs1j7tHdu/fI8qahmboqIqEkgpTzEQpSJkg8YMFh/QOCedySVQtz2Zzy4cPX3N1ueb66oq3b99ydXXJcNLn9vYGkU4mSMSjxCJHxI4E8WOBHeyxGsj9v05OEniuw2w6DQDOxiPWFxe8uXsT2NCwSlyvVwfPRcAichwOPEum4qjFAqW8hJxNU5AlSoU8nuOwWq6Ynk2Yz6Y8f/zIcrFgfb5iOJ6g/Pw7w4dvEapapNFs0ul06Xa7HH6fdruNY9tUyyqOadBtNplPp1xfv2KzeROs2OPjE/P5HMeyKBaLnGw/ka41+Rcwoa7sBymjNgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6ec2b04c051a5fbd928e9524649bc21/e4706/hager_domovea_19.avif 230w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/d1af7/hager_domovea_19.avif 460w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/7f308/hager_domovea_19.avif 920w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/e1c99/hager_domovea_19.avif 1380w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/76ea5/hager_domovea_19.avif 1840w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/95b77/hager_domovea_19.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6ec2b04c051a5fbd928e9524649bc21/a0b58/hager_domovea_19.webp 230w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/bc10c/hager_domovea_19.webp 460w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/966d8/hager_domovea_19.webp 920w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/445df/hager_domovea_19.webp 1380w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/78de1/hager_domovea_19.webp 1840w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/8df42/hager_domovea_19.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6ec2b04c051a5fbd928e9524649bc21/81c8e/hager_domovea_19.png 230w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/08a84/hager_domovea_19.png 460w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/c0255/hager_domovea_19.png 920w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/b1001/hager_domovea_19.png 1380w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/161ec/hager_domovea_19.png 1840w", "/en/static/c6ec2b04c051a5fbd928e9524649bc21/a878e/hager_domovea_19.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6ec2b04c051a5fbd928e9524649bc21/c0255/hager_domovea_19.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera should already be listed and the live video be displayed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9efdaeacab47f5637e9d6775e4a3d068/a878e/hager_domovea_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC20lEQVQ4y1WT247qRhBF2zZXG2MMvjPgC7YxcDAMPiOIJopQpHk4UfKW1/z/Z6yom2Fy8lBqudu1elfVbiGE4OcYjUbkeYbnzXFdR8V8PmOxcD/XuTp7htyXEQQeYegjqvsPrCSl2hREcaygEhhFwQPiOj8l+cRJRBB7hElAnASsVkuqqqSuaw6HPaLqrkzmHmHg4zgOhmGon0ajwZfKJzCKIpJ1QpD4RC+xAudFxvl85ng88vb2hphOLMzxmKnjMBwO6ff7xHGklEpgkkSEgUcSR2R5xrpYkRYZVV2TLGM2ZaGAp9PpAdQ0nX5/gGWa9Hq9L4W2bSrQrqlZJhFZlqrSkpcEP/BVzD1PtWZT5GRpqqBisVgwGAyYTqeYpqkUSuDMMfH9herhxDKVYl0I5mONS9JnMzNY2wJ3rGFo4rP3OcKyLAWcTCbouq4UFkVOFPoK6C1c7ImlEtZLj1+amN9Tk78am0s+ZB0MCWbDT2CBGI/HCijBEiijaRqqqsJ1Zwo2Ho9Ugm0N+Ghd/v425M+3Ob+2LpfKoi1MhoYgy3KEHIT0nixZlaXrbDYFSRKrfU0T6IaOrun0DJ312uK2d/htZ3NILZbeiDw2GfYFRbH5T6HsnwTICzzPU/BBv/9YBwaW9TB8va2VVfJ0zTJOKMuSuiwpyw1d1z2AEiKBMuS373sYhpx+Tyns9TTybKVMXWxSzt0rl9cLt+uNtm3Z7Xbcbje+S6CcqlQmTS2VypLjKMAypSeNzzZovLzEuAub06nlfr/z/v7Ox8cH1+uV/X7Ha3embY+PKT8VapqmgGma4jjT/71xqTYMAva7HV13Uevr+Uz3vaM9HokWNt/2DcK27a8ePpOl76a2hWWOsS2LqW0ThSHb7ZZNUZBnGXEUqYt3zZYsL5j98Q+L+oCQr0N6UKp8AuVQojBgPnMIPI/VcklVlhwOB9r2pF6ELLvZNgSerxwyutwRtsu/hLN3XG1XkuoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9efdaeacab47f5637e9d6775e4a3d068/e4706/hager_domovea_20.avif 230w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/d1af7/hager_domovea_20.avif 460w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/7f308/hager_domovea_20.avif 920w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/e1c99/hager_domovea_20.avif 1380w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/76ea5/hager_domovea_20.avif 1840w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/95b77/hager_domovea_20.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9efdaeacab47f5637e9d6775e4a3d068/a0b58/hager_domovea_20.webp 230w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/bc10c/hager_domovea_20.webp 460w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/966d8/hager_domovea_20.webp 920w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/445df/hager_domovea_20.webp 1380w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/78de1/hager_domovea_20.webp 1840w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/8df42/hager_domovea_20.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9efdaeacab47f5637e9d6775e4a3d068/81c8e/hager_domovea_20.png 230w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/08a84/hager_domovea_20.png 460w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/c0255/hager_domovea_20.png 920w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/b1001/hager_domovea_20.png 1380w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/161ec/hager_domovea_20.png 1840w", "/en/static/9efdaeacab47f5637e9d6775e4a3d068/a878e/hager_domovea_20.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9efdaeacab47f5637e9d6775e4a3d068/c0255/hager_domovea_20.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2afd218c4b526a69a27e98847ad3800d/a878e/hager_domovea_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGElEQVQ4y0WS2W7qZhRGHYYwGDCDAdvYeMYYDNjGkLQKBEwGck6lSsl9nqSv0mddFT5Ve/Hpv/qX9rf3EubzgMfHB9JtiuNYGMYE2zHxfQ/LmqJpI3RdwZhq6LqKaRpY9hTb+RXLNop/nucwn88QbMfG81xMdYg2llEUmamhYttGEcNQMXQF05xgmjrLaIm7cHHmNkHkMw99vr6++P7+5sePDwTXc3EdC3X3xNj1GMsSljVBklo0GzUsS8fQVSxTx7ZNVpsV3sIlzhL80CcIfX7+8ZPPz08+Pj4QZkGAa5sM/vobOf8TRaoXNQaDHt1uB8ucMDU0LNPAdR3WyZr5KmC737NJNiyjkHN+5uXlhbe3NwTP9wnDgMUqwvVslFEfc6phmmrxTqf/A2e+xzpekWQxYbQkjEKi1ZL8cuZ6vf4LnPn4M4+prjIa9hnK3QJ0q32bzjA0DF3DnOoEwYxwEeKHLtFmUdRN05hzfuL19fUXUB708RwX13botEU6rUZxiDRNiOMNijJCU8cFdB4EbLcJSbpmmyVE0ZJNHLF/2HK5XHh/f0cQGzU0VcGamnSlNmKzxkQbs9tlrNcrarV76o0a8qDHIgxJk5gsS0i3Mds0Yb/POJ+fC1gxoeM5hVujkYzYrHNfLaGOB3R7XTqdNuVSibs7AUkS8X2XS37mfHrmeHhim8Q8Hw6cjk+cT0deX18QdENHbDWp3pdp1yv0Gvf0pSb1+g1eoVopUy7f0W5VCy/T7YZ4uyLb7UjWax73DxyPR07Pz7zkFwRFU5HHY+TxCFlqMm5XGHbqdCWRrtSiWi0jCAItsc5I6REsXN6v12JnD/sdeZ6T52eeDr/zuM8QZHnAoC/R60p0xBpiVaDXqhcS3zy8wW4plUrIw15xqNuuLpec3x4fuH5cC2WyXUycRAiVSum/T7fc6oliHV1TGHQ7tJoNpHaLniSxCBc87PdkaUKy2RCvV5xPpyKB76NPNIRarcJwOMBxbIIgKFy7Ce57HsZEZTpRmTk28WrF8XDk7e3XNQ+HA7tsx3KxwDQM+l2J8p3AP1MzjTN0+2hLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2afd218c4b526a69a27e98847ad3800d/e4706/hager_domovea_21.avif 230w", "/en/static/2afd218c4b526a69a27e98847ad3800d/d1af7/hager_domovea_21.avif 460w", "/en/static/2afd218c4b526a69a27e98847ad3800d/7f308/hager_domovea_21.avif 920w", "/en/static/2afd218c4b526a69a27e98847ad3800d/e1c99/hager_domovea_21.avif 1380w", "/en/static/2afd218c4b526a69a27e98847ad3800d/76ea5/hager_domovea_21.avif 1840w", "/en/static/2afd218c4b526a69a27e98847ad3800d/95b77/hager_domovea_21.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2afd218c4b526a69a27e98847ad3800d/a0b58/hager_domovea_21.webp 230w", "/en/static/2afd218c4b526a69a27e98847ad3800d/bc10c/hager_domovea_21.webp 460w", "/en/static/2afd218c4b526a69a27e98847ad3800d/966d8/hager_domovea_21.webp 920w", "/en/static/2afd218c4b526a69a27e98847ad3800d/445df/hager_domovea_21.webp 1380w", "/en/static/2afd218c4b526a69a27e98847ad3800d/78de1/hager_domovea_21.webp 1840w", "/en/static/2afd218c4b526a69a27e98847ad3800d/8df42/hager_domovea_21.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2afd218c4b526a69a27e98847ad3800d/81c8e/hager_domovea_21.png 230w", "/en/static/2afd218c4b526a69a27e98847ad3800d/08a84/hager_domovea_21.png 460w", "/en/static/2afd218c4b526a69a27e98847ad3800d/c0255/hager_domovea_21.png 920w", "/en/static/2afd218c4b526a69a27e98847ad3800d/b1001/hager_domovea_21.png 1380w", "/en/static/2afd218c4b526a69a27e98847ad3800d/161ec/hager_domovea_21.png 1840w", "/en/static/2afd218c4b526a69a27e98847ad3800d/a878e/hager_domovea_21.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2afd218c4b526a69a27e98847ad3800d/c0255/hager_domovea_21.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/54a6997e313326b7e42f02c8a8058be6/a878e/hager_domovea_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVQ4y22UyY7kRBRFo6sqRzvTQ9iO8Bx2OqfKzJ5UdEMVIBCov4AlEt1C/AFSAxILFgjBD/AJbBBiw/cdFEEVIiUWV+9Z1rtxb/hdi5ub5zx/9pT1sKJpKvq+ZRh6jGlQKkHrlKatMX1H3TYOjWlpO+Ng+9V6cOhWPeJ4OrLdrh1ZUSjqOmcYOnb7DafTNX3X0HYNw2ZNP6zcoCV/ILXVElnYZ7GqcupCkRcKlcVUpaIoMpIk4tNPPuZ42NL3hndevnB47+6W3fXeKf2vygeI+O2vVC8/pEwjlJI0de5sCiHI89QdYC1XzT2c7faM5Iyw++o72qc3FFnsCOtK07YlWRbTWbtNgdIZicoI44ggXBKEAUEUnmEZBiRZinh194LdukfrzFm2hMYU5DqmMzV1VaBUhkwTZr6HlyrmMmHue2eYeXMiGSNu3/+AzWaNjEOiwKcsMrbbgc2mpzMNVanRuSZOpFMYpRlhmv7Ty9jBvrM10wqxXg3sNztkFDKfjdAq5smTx9ze3VGWOXEckKQSXeROpUxiZCJd/7+Wda6oqoIoCphMRmRp5NZov9+zWPju43gLj9YYVJGj65rcosjxlwtn11v4rjrLdVMhZcTCmzK+ekQS+YRh4Ihm04mri6XvbD3coScTPN9jESz/hSW3qsXMm3E5umQ+viBbzIi8EWGwZDIZM52MeSQEUsZucV1SOkPd2cU2bn1q054lSORlSdnU6CQk9S/IliOSaImMl47QKkzTlMPp6Iab6xP14THtMNDaxPS9qw9LLqzV2XTMZHTJ5FIwvRJ0pqUotFNnCWMp2dp0NBWmqWgtcakxOsFoSVsVNPeJEXboQghGVxfuzjx/is4SlIyIgyVJFFJWJcam5fSM2Q+/MfnpT5Kf/8D78XfGv/xF9uozTKFo+g6Ra8Vuv+d4PHE6ndhsBg6HA6uuo8o1g/2brHoXv3p3Tfj51wSvvyV98w3RF28Jvvye/N2P7lV2/A1N2ozQieYmvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54a6997e313326b7e42f02c8a8058be6/e4706/hager_domovea_22.avif 230w", "/en/static/54a6997e313326b7e42f02c8a8058be6/d1af7/hager_domovea_22.avif 460w", "/en/static/54a6997e313326b7e42f02c8a8058be6/7f308/hager_domovea_22.avif 920w", "/en/static/54a6997e313326b7e42f02c8a8058be6/e1c99/hager_domovea_22.avif 1380w", "/en/static/54a6997e313326b7e42f02c8a8058be6/76ea5/hager_domovea_22.avif 1840w", "/en/static/54a6997e313326b7e42f02c8a8058be6/95b77/hager_domovea_22.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/54a6997e313326b7e42f02c8a8058be6/a0b58/hager_domovea_22.webp 230w", "/en/static/54a6997e313326b7e42f02c8a8058be6/bc10c/hager_domovea_22.webp 460w", "/en/static/54a6997e313326b7e42f02c8a8058be6/966d8/hager_domovea_22.webp 920w", "/en/static/54a6997e313326b7e42f02c8a8058be6/445df/hager_domovea_22.webp 1380w", "/en/static/54a6997e313326b7e42f02c8a8058be6/78de1/hager_domovea_22.webp 1840w", "/en/static/54a6997e313326b7e42f02c8a8058be6/8df42/hager_domovea_22.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54a6997e313326b7e42f02c8a8058be6/81c8e/hager_domovea_22.png 230w", "/en/static/54a6997e313326b7e42f02c8a8058be6/08a84/hager_domovea_22.png 460w", "/en/static/54a6997e313326b7e42f02c8a8058be6/c0255/hager_domovea_22.png 920w", "/en/static/54a6997e313326b7e42f02c8a8058be6/b1001/hager_domovea_22.png 1380w", "/en/static/54a6997e313326b7e42f02c8a8058be6/161ec/hager_domovea_22.png 1840w", "/en/static/54a6997e313326b7e42f02c8a8058be6/a878e/hager_domovea_22.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/54a6997e313326b7e42f02c8a8058be6/c0255/hager_domovea_22.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49ab7ee44b0564606a2dc70e2746a35d/a878e/hager_domovea_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeklEQVQ4y42T+W6bQBjEN3ZsTHyxgFludrlsDPiKk0hxkrZqmz5Jn6FSX6SPO9VuipWq5x+jTyDtj5nZD3I8HnDY71DkGeI4RJomyPMUnMdgzIbrLhAnERLBEcYRoiT+ZcY8QZpnEFkK0rQNlstCwXyfIYo85LnAqirRtmukIgYXCdrtFnXboKrXWDf1T1MCeSqUSBZ6iHwGz2dgjokwYPB9B7ZN8eH9OzT1EiLleHr7Bh8/PeP0+IBm0yptdi8fke4lVIqYX74hvHtCsKBgzEIceSomIQSet0DgO0gzgXa7URAJWK0rBZFxpaQzGVc5FJ+/Itkd4TumAkahiyQJ4DgmhIjVB9KUKzedMwnPywJZkSMvCgWXQDnJ8+kWqyKF6zoqsgRy7sNzTQgeIQw8CMEVoFiWZ8lneRFSXX8KeP/wiLIsYJkG6HyiIi6XOcoyVQ4DjyHLUuyvr3E4vujm7lbFlnG7/iRYAYssR1WuYFEDV/oALjOx3W5wfzohCDyYdI4wClSkric5Xzvr3img6zGEoQ9K59C0AZwFVWtUVRWm04m6HOYyHG9vVHe7wx7b/U711zmUOkeO4hCWRTEdjzC8vIBNJzCMuQLp+khNhy1Qb1q1c53K1fLco4x8dqiPdfQHfVwNe3CmOuh4AGM+g6YNMdKGCmjb1m8jvq7h7NALAgRxBNc2sJj04MwGsOkMljk7A6lJwTOB+Mdv9jcRGVUfDaEN+tD6BKNLAsET+L6LC0IU0KCGciIPdJ39SUQe6hGCwWUP+kjDeDKC69hgFoU5n8GmBoLAP6/HP4Gey7CqKjRNi7ZtUZY56rpGJgRCz0XOE7WH/wOTwO/7kJZ8f/ZlSgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49ab7ee44b0564606a2dc70e2746a35d/e4706/hager_domovea_23.avif 230w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/d1af7/hager_domovea_23.avif 460w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/7f308/hager_domovea_23.avif 920w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/e1c99/hager_domovea_23.avif 1380w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/76ea5/hager_domovea_23.avif 1840w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/95b77/hager_domovea_23.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49ab7ee44b0564606a2dc70e2746a35d/a0b58/hager_domovea_23.webp 230w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/bc10c/hager_domovea_23.webp 460w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/966d8/hager_domovea_23.webp 920w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/445df/hager_domovea_23.webp 1380w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/78de1/hager_domovea_23.webp 1840w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/8df42/hager_domovea_23.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49ab7ee44b0564606a2dc70e2746a35d/81c8e/hager_domovea_23.png 230w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/08a84/hager_domovea_23.png 460w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/c0255/hager_domovea_23.png 920w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/b1001/hager_domovea_23.png 1380w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/161ec/hager_domovea_23.png 1840w", "/en/static/49ab7ee44b0564606a2dc70e2746a35d/a878e/hager_domovea_23.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49ab7ee44b0564606a2dc70e2746a35d/c0255/hager_domovea_23.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8076ab87771df5ee53f9eb45b08dc88e/a878e/hager_domovea_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQ4y42T227bOBRFFUu+yLJiy7J150WSL7KdxJKbYpoiCdACeZgvmF/qz/Qb10BMUQzQDuqHDR6SOIvnbJJW0+z56+kTl0tHVSqEyChLyW63QSlBHK/I84SyLpFaIZT8RYUUqFJTbzdYZanZFglS5KRZTBKHKJmR5zGXy5mX5yfqWrHd72gvHd2HC+e25dy1Ju7XLo8fON3fmQOtqjmQ//0PoqpIkxVxtETrHN/3CII5d6c9WhXoStMcD+yaPc2hMWOv/Y+43+urtOr2kfDbd4rDHWm0JI5DtMqJopAguEUUqVG9qXl7ezMtPr8803Yt9w/3PH1+oqwqvnz9+g7sPXz99JFKS9IkIo7eW5YiQasMJXNjhy41D+ezSeqrqjY1m93WxP3a4XR8B/bevLy+orVkFQaswrkBVpWgLAWiyIyUVsajPkn+iH8nax0uaR9adpsttzMPfzYxLT5+fKS7dMTRylT+X1g//p8sfzYlyxKUEMznPtPpmDyL6bqO4+nIcOgwcccIJa4D9mZLWbBeh3jTMSPnhiRa4vs+njfFHgyw7cH1wKzImXouztDGnziE3pjlrYvruoxHQxx7wNAZIOSVwCRLiZKEOE1ZLzyimc36dkKwmBEsfBxngD24+fkb/ghcLgMW85l5yN5kiOtYzL0xVaVZzH0syzIqRHEd0LZvfibZNxajkYPvT8nTmFUwZ+F7hMECqeR1QNcdmVtumobT6Y5j/712GzNXRY7MU3Z1ZR72n2A98F9uE3gTz1ukIQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8076ab87771df5ee53f9eb45b08dc88e/e4706/hager_domovea_24.avif 230w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/d1af7/hager_domovea_24.avif 460w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/7f308/hager_domovea_24.avif 920w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/e1c99/hager_domovea_24.avif 1380w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/76ea5/hager_domovea_24.avif 1840w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/95b77/hager_domovea_24.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8076ab87771df5ee53f9eb45b08dc88e/a0b58/hager_domovea_24.webp 230w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/bc10c/hager_domovea_24.webp 460w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/966d8/hager_domovea_24.webp 920w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/445df/hager_domovea_24.webp 1380w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/78de1/hager_domovea_24.webp 1840w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/8df42/hager_domovea_24.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8076ab87771df5ee53f9eb45b08dc88e/81c8e/hager_domovea_24.png 230w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/08a84/hager_domovea_24.png 460w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/c0255/hager_domovea_24.png 920w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/b1001/hager_domovea_24.png 1380w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/161ec/hager_domovea_24.png 1840w", "/en/static/8076ab87771df5ee53f9eb45b08dc88e/a878e/hager_domovea_24.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8076ab87771df5ee53f9eb45b08dc88e/c0255/hager_domovea_24.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a8247aa0bb66447b20f5125c727ad93/a878e/hager_domovea_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0ElEQVQ4y5WTS28jRRSFa5I4tuNnt9v97uqqftnG7thxPEkmyQSNIrFhzXZ2s0LisUKMQGxZABIrNgjY8Ds/6LIHBpgNi6NbXa3+6tx7qsVut2V7saEscqSMybKUoshQSuJ5Dr4/RaYJKtMkqTSSKv1HzYrcSOcZYlWvmM1KAwtDjyQJKArNfFFR1++RZZJUSYqqJC+Lg/brZq8BzRZz85xqhcjjgCT0CEIPz7WJI48wdHEci8fHF6yWM3SmqNdrtrtLnl7tOK+XXN1cc3G5NXp6fUU5q4xbYX/xE/H1A9HUwvMmyCQwbQohCIKpOSBJIpamk4qorFjcvyCSkvq8RmcZZVWyqus9UL96TbrZEbq2ASaxT5pGuK6N1pJUhkRxyN39PbvLLelqzbOPXiIzzcPDc9Pu5mLDzbMbIpkgPri7Yl5m+L5rWm6ASoUEvo1WCUkcmFDM7JrglEQX+V9hNHNrAmsCaUISt/fPqaqSiT3GGvWJQpfZrKCqMrSSRGHjeJ9oowaQHuqbvbffiTIvWFRzJtaYs24L37NZr8+5vbsjigJsa2RaTjNtQLIBHmDpYZ2+tSf8wCOOQyxrRLvdwp1a5hotFgsGg74JZzKdoLLsnZB/SyQyZjKxGPQ6nJ48wbH6jMcjA+p22qY6/wfY7XU5bh1zdnqEO+hi9VqMR0Pa7VM67VOeNA4dG3UYetPyu/RmHCKIIhO374yZ9o9why0ca8jEHhpg49CyrUOKCpnn/5X+e76iabXbOaXdOqZ9LOicCLRKCUPfuGuAY2uMzvf/up6OjJSzr9oZopqrdYCK5qMjIWidHJmZ9fodfNfBm1jYoyGONSaKI1QaE51fcvblz5x99Rv217/Se/0L3W9+x3//Q1TkmcsuAt9jvliwWtXUdU1VFSyXS3KtiQOfQqXkB3fxasPw428ZfPoDzmffM/zkO/qf/0hw+7h3+SfwD2YZm4A0jsXsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a8247aa0bb66447b20f5125c727ad93/e4706/hager_domovea_25.avif 230w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/d1af7/hager_domovea_25.avif 460w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/7f308/hager_domovea_25.avif 920w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/e1c99/hager_domovea_25.avif 1380w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/76ea5/hager_domovea_25.avif 1840w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/95b77/hager_domovea_25.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a8247aa0bb66447b20f5125c727ad93/a0b58/hager_domovea_25.webp 230w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/bc10c/hager_domovea_25.webp 460w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/966d8/hager_domovea_25.webp 920w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/445df/hager_domovea_25.webp 1380w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/78de1/hager_domovea_25.webp 1840w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/8df42/hager_domovea_25.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a8247aa0bb66447b20f5125c727ad93/81c8e/hager_domovea_25.png 230w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/08a84/hager_domovea_25.png 460w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/c0255/hager_domovea_25.png 920w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/b1001/hager_domovea_25.png 1380w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/161ec/hager_domovea_25.png 1840w", "/en/static/5a8247aa0bb66447b20f5125c727ad93/a878e/hager_domovea_25.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a8247aa0bb66447b20f5125c727ad93/c0255/hager_domovea_25.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4461a3ce7c2ef05ed20079771b593468/a878e/hager_domovea_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQ4y22TyYokVRSGbw2ZkUPM0415yojMjMzIquyizKrqUqHppldu3Qkq4hsIrYIbQVBfwGcQxIXg830S1+6iQRc/50bA/eL8/zkhbm9vuHl2pGtXFEVG05S0bUNVFUjpE0UBRZlTNTV5WSgVVUlZV0rjedW1SvWqQQyHgc2mU7AkkeR5TNvWbPs1h8OOpi4oq4J23dG0K3VxhL+DjnUEje9GiVUWkyeSOJHI0CVLJUkS4vsOr16+YNhvaFYVt6cTH9ydeP74yHbXq07f7/JdFe6b38juPiYNHKT0KPJY2RRCEEeB+kBeZGR5RpKlT8qKnDTPlMZznKUMVwdE/fX3lMdbktBVwDyLKMuUMHSpR7tFipQBju+zNA30/5FhmcwWc8b4xOvHE9uuIYpCZXkEVlVCHLnUVU6ZJziey/bVJzQ3d1imriBLQ3/S+KzNZ/T7HeL5hx+xXnd4ro1j6aRJyGbTsl431FVBnsb4vke8HQiKCsuxMUZZ5pNM22KhL9kPA6JbtfTrLZ5js5hPiKTL9fWVCj9NY1zXwg98XNdRl4wowQij/wDnywX7YY+IYkmWJTiOhaZNCANHrVHf9xiGroZjmAZeGGC7Drbrvq0Oru8peYGvOr86XiPGCXqeg7GcMb08w3d0bNtSoPlMewvU1a6pCacxaZaq6Y7dvT+U3djhfDnnYnLBYnpOaMxxlhNsy0TTpsy0KWdCoOsLZByhj/biDDNK/s3N0FUM42CmM41+t0PEaUpa5ES+TaCfE5oTfMfEc00VgVBAnTCSuL6PJyWeDFUEqkqJL0MVwfWzI2K0Op9N0SYXaBeC2aWgrkqSJOLsTCig53tqJYqyUP94VVdUqaSOA5rYpy5SsrLk7v4eMVo6F4LJ5bnKbKnPiEIf6Tm4lolnW+RFzrpbkQxHZj/+jvbzX/i//Mnipz+Y/vo38vWn5NLj9PCAiCPJtu8ZhgOHw4H1umW/37Oqa7I4oq1Kuq6lbVdkmx7ns2+wvviO4MtvcT5/g/XVDyT3L8gSyenhnn8AnrSM6FYtHYEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4461a3ce7c2ef05ed20079771b593468/e4706/hager_domovea_26.avif 230w", "/en/static/4461a3ce7c2ef05ed20079771b593468/d1af7/hager_domovea_26.avif 460w", "/en/static/4461a3ce7c2ef05ed20079771b593468/7f308/hager_domovea_26.avif 920w", "/en/static/4461a3ce7c2ef05ed20079771b593468/e1c99/hager_domovea_26.avif 1380w", "/en/static/4461a3ce7c2ef05ed20079771b593468/76ea5/hager_domovea_26.avif 1840w", "/en/static/4461a3ce7c2ef05ed20079771b593468/95b77/hager_domovea_26.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4461a3ce7c2ef05ed20079771b593468/a0b58/hager_domovea_26.webp 230w", "/en/static/4461a3ce7c2ef05ed20079771b593468/bc10c/hager_domovea_26.webp 460w", "/en/static/4461a3ce7c2ef05ed20079771b593468/966d8/hager_domovea_26.webp 920w", "/en/static/4461a3ce7c2ef05ed20079771b593468/445df/hager_domovea_26.webp 1380w", "/en/static/4461a3ce7c2ef05ed20079771b593468/78de1/hager_domovea_26.webp 1840w", "/en/static/4461a3ce7c2ef05ed20079771b593468/8df42/hager_domovea_26.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4461a3ce7c2ef05ed20079771b593468/81c8e/hager_domovea_26.png 230w", "/en/static/4461a3ce7c2ef05ed20079771b593468/08a84/hager_domovea_26.png 460w", "/en/static/4461a3ce7c2ef05ed20079771b593468/c0255/hager_domovea_26.png 920w", "/en/static/4461a3ce7c2ef05ed20079771b593468/b1001/hager_domovea_26.png 1380w", "/en/static/4461a3ce7c2ef05ed20079771b593468/161ec/hager_domovea_26.png 1840w", "/en/static/4461a3ce7c2ef05ed20079771b593468/a878e/hager_domovea_26.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4461a3ce7c2ef05ed20079771b593468/c0255/hager_domovea_26.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05f539f2fa33733fa0d61d7bb823d90e/a878e/hager_domovea_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeklEQVQ4y42T+W6bQBjEN3ZsTHyxgFludrlsDPiKk0hxkrZqmz5Jn6FSX6SPO9VuipWq5x+jTyDtj5nZD3I8HnDY71DkGeI4RJomyPMUnMdgzIbrLhAnERLBEcYRoiT+ZcY8QZpnEFkK0rQNlstCwXyfIYo85LnAqirRtmukIgYXCdrtFnXboKrXWDf1T1MCeSqUSBZ6iHwGz2dgjokwYPB9B7ZN8eH9OzT1EiLleHr7Bh8/PeP0+IBm0yptdi8fke4lVIqYX74hvHtCsKBgzEIceSomIQSet0DgO0gzgXa7URAJWK0rBZFxpaQzGVc5FJ+/Itkd4TumAkahiyQJ4DgmhIjVB9KUKzedMwnPywJZkSMvCgWXQDnJ8+kWqyKF6zoqsgRy7sNzTQgeIQw8CMEVoFiWZ8lneRFSXX8KeP/wiLIsYJkG6HyiIi6XOcoyVQ4DjyHLUuyvr3E4vujm7lbFlnG7/iRYAYssR1WuYFEDV/oALjOx3W5wfzohCDyYdI4wClSkric5Xzvr3img6zGEoQ9K59C0AZwFVWtUVRWm04m6HOYyHG9vVHe7wx7b/U711zmUOkeO4hCWRTEdjzC8vIBNJzCMuQLp+khNhy1Qb1q1c53K1fLco4x8dqiPdfQHfVwNe3CmOuh4AGM+g6YNMdKGCmjb1m8jvq7h7NALAgRxBNc2sJj04MwGsOkMljk7A6lJwTOB+Mdv9jcRGVUfDaEN+tD6BKNLAsET+L6LC0IU0KCGciIPdJ39SUQe6hGCwWUP+kjDeDKC69hgFoU5n8GmBoLAP6/HP4Gey7CqKjRNi7ZtUZY56rpGJgRCz0XOE7WH/wOTwO/7kJZ8f/ZlSgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05f539f2fa33733fa0d61d7bb823d90e/e4706/hager_domovea_27.avif 230w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/d1af7/hager_domovea_27.avif 460w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/7f308/hager_domovea_27.avif 920w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/e1c99/hager_domovea_27.avif 1380w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/76ea5/hager_domovea_27.avif 1840w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/95b77/hager_domovea_27.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05f539f2fa33733fa0d61d7bb823d90e/a0b58/hager_domovea_27.webp 230w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/bc10c/hager_domovea_27.webp 460w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/966d8/hager_domovea_27.webp 920w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/445df/hager_domovea_27.webp 1380w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/78de1/hager_domovea_27.webp 1840w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/8df42/hager_domovea_27.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05f539f2fa33733fa0d61d7bb823d90e/81c8e/hager_domovea_27.png 230w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/08a84/hager_domovea_27.png 460w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/c0255/hager_domovea_27.png 920w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/b1001/hager_domovea_27.png 1380w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/161ec/hager_domovea_27.png 1840w", "/en/static/05f539f2fa33733fa0d61d7bb823d90e/a878e/hager_domovea_27.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05f539f2fa33733fa0d61d7bb823d90e/c0255/hager_domovea_27.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/a878e/hager_domovea_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACT0lEQVQ4y42U627aQBSENxAwhJvXNvZ6be/NF3DATZqikiahVZS2Uh+kz1CpL9LHncpbkEIiqvwY7a/zeWbOrslmc4Ob99coixxCpDBGoigMlBKIogCMBRAygzIamRRWQklIrY5OU+TQuQFZN2ssFqWFcR4hy2IUhcayrtA0lzBGQCqBclHZobws7OABdgC2H2xF8jRGxiPEPEIUekiTCJyHCAKKp6dHrFdLKC1xs/lgtf10i3p1aZ0egAdoK+L9+oN0+xnJnCKKfIgsBmNzEEIQx3MkPLQVPOx2diAV2ZG7lyL652/I6w146FlgljJImSAMPWgtkKUx8txge3sLpRWyfZ9yH/G5LPD7/UcsSwPGQhu5BSrFETMPWmUW2HYYc4Zys8X6y7dXcY8c3j3sUFUlfM8FnY1txMWiQFUZaCWQcAbZ9iNSrO52uPr64x9w39lLkTIvUFdL+NTFxbAHFnm4unqHu/t7JEkMj86QpNxG1EbD5AZSt8MnHLI4QppyUDqD4/QQzqm9RnVdYzIZ2+X4cx/KmGdO1B76ejkkEyl8n2IyGqB/foaAjuG6MwsaDhx7Bs+AdlDJk5smw9EQ3V4XF/0OwskQdNSDO5vCcfoYOH2ctQ4DD+rFZT65lDhJkIgMLHAxH3cQTnsI6BS+N7XA1iH16KvXcRLYRh0O+nB6XThdgsE5gVYSnDPrrgW61H07sB3qEILeecd2NhoPwMIAkU/hzaYIqIsk4Uc/g/9HZhGWdY31ukHTNKiqAqvVCrnWSGOGQkn7Ut4Ca4F/AdmVka1bJ5guAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/e4706/hager_domovea_28.avif 230w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/d1af7/hager_domovea_28.avif 460w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/7f308/hager_domovea_28.avif 920w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/e1c99/hager_domovea_28.avif 1380w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/76ea5/hager_domovea_28.avif 1840w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/95b77/hager_domovea_28.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/a0b58/hager_domovea_28.webp 230w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/bc10c/hager_domovea_28.webp 460w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/966d8/hager_domovea_28.webp 920w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/445df/hager_domovea_28.webp 1380w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/78de1/hager_domovea_28.webp 1840w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/8df42/hager_domovea_28.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/81c8e/hager_domovea_28.png 230w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/08a84/hager_domovea_28.png 460w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/c0255/hager_domovea_28.png 920w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/b1001/hager_domovea_28.png 1380w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/161ec/hager_domovea_28.png 1840w", "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/a878e/hager_domovea_28.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff7b55b9ee81fd492f186f98aeb1f2f1/c0255/hager_domovea_28.png",
              "alt": "Homee INSTAR Cameras",
              "title": "Homee INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Placeholder Text for Sequences`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      